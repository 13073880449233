.otf {
    max-height: 100vh;
    min-height: 100vh;
    height: 100vh;
    max-width: 100vw;
    min-width: 100vw;
    width: 100vw;
    background: #000;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.otf .container-otf {
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}

.otf .container-otf .logo-ct {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: fit-content;
}

.otf .container-otf .logo-ct .logo {
    width: 40vw;
    height: 40vh;
    display: flex;
    background: url('./assets/images/otflogo.png');
    background-repeat: no-repeat;
    background-position: 55% 50%;
    background-size: contain;
}

.otf .container-otf .socials-ct {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: fit-content;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.otf .container-otf .socials-ct .social {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
}

.otf .container-otf .socials-ct .social:hover {
    opacity: 0.8;
}

@media only screen and (max-width: 500px) {
    .otf .container-otf .socials-ct .social {
        width: 25px;
        height: 25px;
    }
}