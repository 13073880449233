.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.otf-container {
  background-color: #000000;
}

.cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.3);
  transition: all .4s ease-in-out;
}

.cover:hover {
  background-color: rgba(0, 0, 0, 0);
}

.trenches {
  background: url('./assets/images/trenches.png');
  /*background-color: rgba(0, 78, 255, 0.2);*/
  background-blend-mode: screen;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90%;
  filter: blur(2px);
  transition: all .4s ease-in-out;
  cursor: pointer;
  overflow: hidden;
}

.trenches:hover {
  background-size: 100%;
}

.trenches:hover, .otfgaming:hover {
  filter: blur(0);
  background-color: rgba(255, 0, 0, 0);
}

.otfgaming {
  background-image: url('./assets/images/otflogo.png');
  /*background-color: rgba(255, 0, 0, 0.2);*/
  background-blend-mode: screen;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 45%;
  filter: blur(2px);
  transition: all .4s ease-in-out;
  cursor: pointer;
  overflow: hidden;
}

.otfgaming:hover {
  background-size: 55%;
}

.rain {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.rain.back-row {
  display: none;
  z-index: 1;
  bottom: 60px;
  opacity: 0.5;
}

.drop {
  position: absolute;
  bottom: 100%;
  width: 15px;
  height: 120px;
  pointer-events: none;
  animation: drop 0.5s linear infinite;
}

@keyframes drop {
  0% {
    transform: translateY(0vh);
  }
  75% {
    transform: translateY(90vh);
  }
  100% {
    transform: translateY(90vh);
  }
}

.stem {
  width: 1px;
  height: 60%;
  margin-left: 7px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.25));
  animation: stem 0.5s linear infinite;
}

@keyframes stem {
  0% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.splat {
  width: 15px;
  height: 10px;
  border-top: 2px dotted rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  opacity: 1;
  transform: scale(0);
  animation: splat 0.5s linear infinite;
  display: none;
}

@keyframes splat {
  0% {
    opacity: 1;
    transform: scale(0);
  }
  80% {
    opacity: 1;
    transform: scale(0);
  }
  90% {
    opacity: 0.5;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}
